.page {

  &_mv {
    @include responsive((
      margin-bottom: 320
    ));
    @include mq(sp) {
      margin-bottom: mycalcSP(128);
    }
  }

  &_desc {
    letter-spacing: .1em;
    box-sizing: border-box;
    @include responsive((
      width: 2560,
      padding: 0 80,
      font-size: 32,
      line-height: 80,
      margin: 0 auto 240,
    ));
    @include mq(sp) {
      letter-spacing: .05em;
      width: mycalcSP(750);
      padding: mycalcSP(0 72);
      font-size: mycalcSP(30);
      line-height: mycalcSP(64);
      margin-bottom: mycalcSP(128);
    }
  }

  &_sec {
    @include responsive((
      padding-bottom: 8
    ));
    @include mq(sp) {
      padding-bottom: mycalcSP(8);
    }
  }

  &_list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: wrap;
    @include mq(sp) {
      width: 100%;
      box-sizing: border-box;
      padding: mycalcSP(0 72);
    }

    &_term {
      width: 100%;
      letter-spacing: .1em;
      @include responsive((
        font-size: 48,
        line-height: 64,
        margin-bottom: 128,
      ));
      @include mq(sp) {
        box-sizing: border-box;
        padding: mycalcSP(0 72);
        font-size: mycalcSP(40);
        line-height: mycalcSP(64);
        margin-bottom: mycalcSP(64);
      }
    }

    .box_card {
      margin-bottom: 0;
      @include responsive((
        width: 736,
        margin-right: 96,
        margin-top: 208,
      ));
      @include mq(sp) {
        width: mycalcSP(288);
        margin-right: mycalcSP(30);
        margin-top: mycalcSP(96);

        &:nth-child(-n+2) {
          margin-top: 0;
        }

        &:nth-child(even) {
          margin-right: 0;
        }
      }

      @include minq(sp) {
        &:nth-child(-n+3) {
          margin-top: 0;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      &_link {
        width: 100%;
      }

      &_data {
        width: 100%;

        .product_name {
          width: 100%;
          letter-spacing: .05em;
          @include responsive((
            font-size: 32,
            line-height: 48,
            margin-bottom: 16,
          ));
          @include mq(sp) {
            font-size: mycalcSP(30);
            line-height: mycalcSP(40);
            margin-bottom: mycalcSP(8);
          }
        }

        .product_meta {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          @include responsive((
            margin-bottom: 48
          ));
          @include mq(sp) {
            margin-bottom: mycalcSP(24);
          }
        }

        .product_id {
          letter-spacing: .05em;
          @include responsive((
            font-size: 28,
            line-height: 48,
          ));
          @include mq(sp) {
            width: 100%;
            font-size: mycalcSP(26);
            line-height: mycalcSP(32);
            margin-bottom: mycalcSP(16);
          }
        }

        .product_price {
          text-align: right;
          letter-spacing: .05em;
          @include responsive((
            font-size: 40,
            line-height: 48,
          ));
          @include mq(sp) {
            width: 100%;
            font-size: mycalcSP(32);
            line-height: mycalcSP(48);
            text-align: left;
          }
          small {
            letter-spacing: .05em;
            @include responsive((
              font-size: 28,
              line-height: 48,
              margin-left: 12,
            ));
            @include mq(sp) {
              font-size: mycalcSP(24);
              line-height: mycalcSP(48);
              margin-left: mycalcSP(12);
            }
          }
        }

        .product_cta {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include mq(sp) {
            flex-wrap: wrap;
          }
        }

        .btn {
          margin: 0;
          @include responsive((
            width: 330,
            height: 88,
          ));
          @include mq(sp) {
            width: mycalcSP(254);
            height: mycalcSP(60);
          }

          &_box {
            width: 100%;
          }

          &_link {
            padding-right: 0;
            padding-left: 0;
            width: 100%;
            justify-content: space-between;

            &_txt {
              @include responsive((
                font-size: 32,
                line-height: 48,
                margin-top: 4,
              ));
              @include mq(sp) {
                font-size: mycalcSP(28);
                line-height: mycalcSP(44);
                margin-top: mycalcSP(4);
              }
            }

            .ico_arw {
              @include mq(sp) {
                width: mycalcSP(32);
                height: mycalcSP(32);
              }
            }
          }
        }

        .btn_round {
          margin: 0;
          @include responsive((
            width: 352,
            height: 112,
          ));
          @include mq(sp) {
            width: 100%;
            height: mycalcSP(100);
            margin-top: mycalcSP(32);
          }

          &_link {
            &_txt {
              @include responsive((
                font-size: 32,
                line-height: 48,
              ));
              @include mq(sp) {
                font-size: mycalcSP(28);
                line-height: mycalcSP(44);
              }
            }

            .ico_arw {
              position: relative;
              top: 0;
              right: 0;
              @include responsive((
                width: 48,
                height: 48,
                margin-left: 16,
              ));
              @include mq(sp) {
                width: mycalcSP(32);
                height: mycalcSP(32);
                margin-left: mycalcSP(24);
              }
            }

            .ico_ec {
              @include responsive((
                margin-right: 16,
                margin-left: 16,
              ));
              @include mq(sp) {
                margin-right: mycalcSP(16);
                margin-left: mycalcSP(16);
              }
            }
          }
        }
      }

      &_thumb {
        @include responsive((
          width: 736,
          height: 736,
          margin-bottom: 48,
        ));
        @include mq(sp) {
          width: mycalcSP(288);
          height: mycalcSP(288);
          margin-bottom: mycalcSP(32);
        }
      }
    }
  }

  &_about {

    &_contents {
      .about_init & {
        @include responsive((
          width: 1380,
          padding-left: 0,
          padding-right: 170,
        ));
        @include mq(sp) {
          width: mycalcSP(750);
          padding: mycalcSP(0 72);
        }
      }

      .about_kyoto & {
        @include responsive((
          width: 1176,
          margin-top: 224,
          padding-left: 264,
          padding-right: 0,
        ));
        @include mq(sp) {
          width: mycalcSP(750);
          margin-top: mycalcSP(0);
          padding: mycalcSP(0 72);
        }
      }

      .about_shop & {
        @include responsive((
          width: 972,
        ));
        @include mq(sp) {
          width: mycalcSP(750);
          padding: mycalcSP(0 72);
        }
      }
    }

    &_figure {
      .about_init & {
        @include responsive((
          width: 850,
          height: 566,
          margin-top: 768,
        ));
        @include mq(sp) {
          width: mycalcSP(750);
          height: auto;
        }
      }

      .about_kyoto & {
        order: -1;
        @include responsive((
          width: 960,
          height: 800,
          margin-top: 0,
        ));
        @include mq(sp) {
          width: mycalcSP(750);
          height: auto;
        }
      }

      .about_shop & {
        @include responsive((
          width: 1184,
          height: 790,
          margin-top: 364,
        ));
        @include mq(sp) {
          width: mycalcSP(750);
          height: auto;
          margin-top: 0;
        }
      }

      &_img {
        width: 100%;
        display: block;
        height: auto;
      }
    }

    &_inner {
      padding-bottom: 0;
    }
  }

  &_shop {
    padding: 0;

    &_ttl {
      width: 100%;
      text-align: center;
      letter-spacing: .1em;
      white-space: nowrap;
      font-weight: 400;
      @include responsive((
        font-size: 80,
        line-height: 128,
        margin-bottom: 160,
      ));
      @include mq(sp) {
        white-space: normal;
        letter-spacing: .05em;
        font-size: mycalcSP(48);
        line-height: mycalcSP(88);
        margin-bottom: mycalcSP(120);
        padding: mycalcSP(0);
      }
    }

    &_sec {
      margin: 0 auto;
      border-bottom: 1px solid $c_border;
      @include responsive((
        width: 1920,
        padding-bottom: 240,
        margin-bottom: 240,
      ));
      @include mq(sp) {
        width: mycalcSP(606);
        padding-bottom: mycalcSP(96);
        margin-bottom: mycalcSP(192);
      }

      &_ttl {
        width: 100%;
        text-align: center;
        letter-spacing: .1em;
        white-space: nowrap;
        font-weight: 400;
        @include responsive((
          font-size: 64,
          line-height: 96,
          margin-bottom: 128,
        ));
        @include mq(sp) {
          white-space: normal;
          letter-spacing: .05em;
          font-size: mycalcSP(40);
          line-height: mycalcSP(76);
          margin-bottom: mycalcSP(64);
          padding: mycalcSP(0);
        }
      }

      &_figure {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        @include responsive((
          width: 1920,
          height: 800,
          margin: 0 auto 80,
        ));
        @include mq(sp) {
          order: -1;
          width: mycalcSP(606);
          height: auto;
          margin: mycalcSP(0 auto 64);
        }

        &_img {
          max-width: none;
          width: 100%;
          height: auto;
        }
      }

      .contents {
        width: 100%;
        background: $c_pink_light;
        margin-bottom: 0;
        @include responsive((
          padding: 128 120,
        ));
        @include mq(sp) {
          padding: mycalcSP(80 40);
        }

        li {
          font-family: $f_mincho;
          @include responsive((
            font-size: 40,
            line-height: 48,
          ));
          @include mq(sp) {
            font-size: mycalcSP(32);
            line-height: mycalcSP(38);
          }
        }
      }
    }

    &_online {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      &_list {
        display: flex;
        align-items: stretch;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid $c_border;
        @include responsive((
          width: 880,
          height: 328,
        ));
        @include mq(sp) {
          width: 100%;
          height: mycalcSP(240);
          margin-bottom: mycalcSP(56);

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          width: 100%;
          background: $c_pink_light;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap;
        }

        &_img {
          display: flex;
          justify-content: center;
          align-items: stretch;
          height: 100%;

          .ec {
            @include mq(sp) {
              width: mycalcSP(120);
            }
          }

          .amazon {
            @include mq(sp) {
              width: mycalcSP(196);
            }
          }
        }

        &_name {
          width: 100%;
          text-align: center;
          background: $c_bg_flower;
          box-sizing: border-box;
          position: relative;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          @include responsive((
            font-size: 28,
            height: 60,
          ));
          @include mq(sp) {
            font-size: mycalcSP(24);
            height: mycalcSP(72);
          }

          img {
            position: absolute;
            @include responsive((
              right: 24,
              top: 16,
            ));
            @include mq(sp) {
              right: mycalcSP(20);
              top: mycalcSP(24);
            }
          }
        }
      }
    }

    &_desc {
      line-height: 2.5;
      @include responsive((
        margin-bottom: 48,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(72);
      }
    }

    &_data {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      @include responsive((
        margin-bottom: 32,
      ));
      @include mq(sp) {
        margin-bottom: mycalcSP(48);
      }

      dt {
        flex-shrink: 0;
        @include responsive((
          width: 324,
          margin-right: 48,
        ));
        @include mq(sp) {
          width: mycalcSP(168);
          margin-bottom: mycalcSP(24);
          margin-right: mycalcSP(24);
        }
      }

      small {
        @include responsive((
          font-size: 24
        ));
        @include mq(sp) {
          font-size: mycalcSP(24);
        }
      }
    }

    .btn {
      justify-content: flex-end;
    }
  }

  &_story {
    &_ttl {
      width: 100%;
      text-align: center;
      letter-spacing: .1em;
      white-space: nowrap;
      font-weight: 400;
      @include responsive((
        font-size: 80,
        line-height: 128,
        margin-bottom: 160,
      ));
      @include mq(sp) {
        white-space: normal;
        letter-spacing: .05em;
        font-size: mycalcSP(48);
        line-height: mycalcSP(88);
        margin-bottom: mycalcSP(120);
        padding: mycalcSP(0);
      }

      .story_technique & {
        text-align: left;
        @include responsive((
          margin-bottom: 96
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(48);
        }
      }

    }

    &_concept {
      @include mq(sp) {
        padding: mycalcSP(160 0 0);
      }

      &_desc {
        letter-spacing: .1em;
        box-sizing: border-box;
        text-align: center;
        @include responsive((
          padding: 0 80,
          font-size: 32,
          line-height: 80,
          margin: 0 auto 240,
        ));
        @include mq(sp) {
          letter-spacing: .05em;
          padding: mycalcSP(0 72);
          font-size: mycalcSP(30);
          line-height: mycalcSP(64);
          margin-bottom: mycalcSP(128);
        }
      }
    }

    &_kumano {
      width: 100%;
      box-sizing: border-box;
      @include responsive((
        padding: 232 0,
        margin-bottom: 320,
      ));
      @include mq(sp) {
        padding: mycalcSP(120 0);
        margin-bottom: mycalcSP(320);
      }

      .page_story_ttl,
      .page_story_concept_desc {
        color: $c_white;
      }

      .page_story_ttl {
        position: relative;
        z-index: 1;
        @include responsive((
          margin-bottom: 96,
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(80);
        }
      }

      .page_story_concept_desc {
        margin-bottom: 0;
        position: relative;
        z-index: 1;
      }

      &_figure {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
        @include responsive((
          height: 1006
        ));
        @include mq(sp) {
          height: mycalcSP(840);
        }

        &_img {
          position: absolute;
          top: 0;
          z-index: 0;
          @include responsive((
            width: 1920,
            height: 1006
          ));
          @include mq(sp) {
            width: mycalcSP(375);
            height: mycalcSP(840);
          }

          &.kyoto {
            right: 50%;
          }

          &.kumano {
            left: 50%;
          }
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: $c_black;
          opacity: .65;
          top: 0;
          left: 0;
          z-index: 1;

          @include mq(sp) {
            content: none;
          }
        }
      }
    }

    &_contents {
      .story_technique & {
        order: -1;
        @include responsive((
          width: 1128,
          padding-left: 0,
          padding-right: 96,
        ));
        @include mq(sp) {
          width: mycalcSP(750);
          padding: mycalcSP(0 72);
          margin-bottom: mycalcSP(120);
        }
      }
    }

    &_figure {
      .story_technique & {
        @include responsive((
          width: 1176,
          height: 784,
        ));
        @include mq(sp) {
          width: mycalcSP(750);
          height: auto;
        }
      }

      &_img {
        width: 100%;
        display: block;
        height: auto;
      }
    }

    &_inner {
      padding-bottom: 0;
      background: none;
      align-items: flex-start;
    }
  }
}


.about {
  .sec {
    margin-bottom: 0;
    @include responsive((
      padding-bottom: 240,
    ));
    @include mq(sp) {
      padding-bottom: mycalcSP(240);
    }
  }
}


.story {

  &_flow {
    .page_story_ttl {
      width: 100%;
      text-align: left;
      @include mq(sp) {
        box-sizing: border-box;
        padding-left: mycalcSP(72);
        margin-bottom: mycalcSP(60);
      }
    }

    .swiper {
      overflow: visible;
      position: static;
      @include responsive((
        height: 776,
        padding-left: 48,
      ));
      width: calc(100vw - ((100vw - 1152px)*.5));

      @include mq(pc) {
        width: calc(100vw - ((100vw - #{mycalc(2304)})*.5));
      }
      @include mq(sp) {
        width: mycalcSP(606);
        height: mycalcSP(608);
        margin: 0 auto;
        padding-left: 0;
      }

      /*
      .archive_card {
        @include responsive((
          padding-left: 48,
        ));
        @include mq(sp) {
          padding-left: 0;
        }
      }
      */

      &-scrollbar {
        position: relative;
        width: calc(100vw - ((100vw - 1152px)*.5));
        @include responsive((
          height: 10,
          //margin-left: 48,
        ));
        @include mq(pc) {
          width: calc(100vw - ((100vw - #{mycalc(2304)})*.5));
        }
        @include mq(sp) {
          width: calc(100vw - #{mycalcSP(72)});
          height: mycalcSP(10);
          margin-left: 0;
          margin-top: mycalcSP(-2);
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          background: $c_white;
          @include responsive((
            height: 2,
            top: 5,
          ));
          @include mq(sp) {
            height: mycalcSP(2);
            top: mycalc(5);
          }
        }

        &-drag {
          cursor: pointer;
          position: relative;
          @include responsive((
            height: 10,
          ));
          @include mq(sp) {
            height: mycalcSP(10);
          }

          &:before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            background: $c_brand;
            @include responsive((
              height: 2,
              top: 5,
            ));
            @include mq(sp) {
              height: mycalcSP(2);
              top: mycalc(5);
            }
          }
        }
      }

      .arw_prev,
      .arw_next {
        @include responsive((
          top: -32,
        ));
        @include mq(sp) {
          display: none;
        }
      }
    }

    .box_card {
      margin-bottom: 0;
      @include responsive((
        margin-right: 144,
      ));
      @include mq(sp) {
        margin-right: mycalcSP(48);
      }

      &_desc {
        height: auto;
        @include responsive((
          margin-bottom: 96,
        ));
        @include mq(sp) {
          margin-bottom: mycalcSP(96);
        }

        &_name {
          @include responsive((
            font-size: 40,
            line-height: 48,
            margin-bottom: 32,
          ));
          @include mq(sp) {
            font-size: mycalcSP(40);
            line-height: mycalcSP(48);
            margin-bottom: mycalcSP(32);
          }
        }
      }
    }

  }

  &_quality {

    .page_story_concept_desc {
      text-align: left;
      margin: 0 auto;
      padding: 0;
      @include responsive((
        width: 1380,
        margin-bottom: 320,
      ));
      @include mq(sp) {
        width: mycalcSP(606);
        margin-bottom: mycalcSP(0);
      }
    }

    &_list {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      @include responsive((
        margin: 120 auto,
      ));
      @include mq(sp) {
        width: mycalcSP(750);
        margin: mycalcSP(96 auto 0);
        padding-bottom: mycalcSP(48);
        overflow: visible;
        justify-content: flex-start;
      }

      &_item {
        overflow: hidden;
        text-align: center;
        position: relative;
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        cursor: pointer;
        @include responsive((
          margin-left: 48,
          width: 320,
          height: 320,
        ));
        @include mq(sp) {
          width: mycalcSP(240);
          height: mycalcSP(240);
          margin-left: mycalcSP(36);
        }

        &.face-brush {
          background-image: url(../images/story/brush-face.png);
        }

        &.cheek-brush {
          background-image: url(../images/story/brush-cheek.png);
        }

        &.shadow-brush {
          background-image: url(../images/story/brush-shadow.png);
        }

        &.lip-brush {
          background-image: url(../images/story/brush-lip.png);
        }

        &.eyeblow-brush {
          background-image: url(../images/story/brush-eyeblow.png);
        }

        &.eyeliner-brush {
          background-image: url(../images/story/brush-eyeliner.png);
        }

        &:first-child {
          margin-left: 0;
        }

        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background: $c_black;
          opacity: .7;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          border-radius: 100%;
          transition: opacity $t_fade;
        }

        &:hover:after {
          opacity: 0;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $c_white;
          position: relative;
          z-index: 1;
          width: 100%;
          height: 100%;
          @include mq(sp) {
            font-size: mycalcSP(24);
            letter-spacing: .05em;
          }
        }
      }
    }

    &_spec {
      box-sizing: border-box;
      @include responsive((
        width: 1500,
        padding: 0 240,
      ));
      @include mq(sp) {
        width: mycalcSP(750);
        padding: mycalcSP(0 72);
      }

      .spec {
        &_ttl {
          width: 100%;
          text-align: center;
          @include responsive((
            font-size: 40,
            line-height: 88,
            margin: 0 auto 36,
          ));
          @include mq(sp) {
            font-size: mycalcSP(40);
            line-height: mycalcSP(60);
            margin: mycalcSP(0 auto 36);
          }
        }

        &_img {
          text-align: center;
          @include responsive((
            margin: 56 auto 80,
          ));
          @include mq(sp) {
            margin: mycalcSP(56 auto 80);
          }
        }

        &_data {
          margin: 0;
          border-top: 1px solid $c_border;
          @include responsive((
            width: 1500,
          ));
          @include mq(sp) {
            width: mycalcSP(606);
          }

          tr {
            border-bottom: 1px solid $c_gray_light;

            &:last-child {
              border-bottom-color: $c_border;
            }

            th {
              position: relative;
              @include responsive((
                width: 496,
                padding: 44 32,
              ));
              @include mq(sp) {
                width: 100%;
                display: block;
                padding: mycalcSP(44 32);
              }

              &:before {
                content: "";
                position: absolute;
                background: $c_pink;
                border-radius: 50%;
                @include responsive((
                  width: 8,
                  height: 8,
                  top: 66,
                  left: 10,
                ));
                @include mq(sp) {
                  width: mycalcSP(8);
                  height: mycalcSP(8);
                  top: mycalcSP(66);
                  left: mycalcSP(10);
                }
              }
            }

            td {
              @include responsive((
                padding: 44 0,
                font-size: 28,
              ));
              @include mq(sp) {
                display: block;
                width: 100%;
                padding: mycalcSP(0 0 44);
                font-size: mycalcSP(28);
              }
            }
          }
        }
      }
    }

    .brush_swiper {
      position: relative;
      margin: 0 auto;
      @include responsive((
        width: 2400,
      ));
      @include mq(sp) {
        width: mycalcSP(750);
        padding: mycalcSP(0 72);
        box-sizing: border-box;
        margin-bottom: mycalcSP(60);
      }
    }

    .spec_swiper {
      position: relative;
      margin: 0 auto;
      @include responsive((
        width: 1980,
      ));
      @include mq(sp) {
        width: mycalcSP(750);
      }

      .swiper-wrapper {
        width: 100%;

        .swiper-slide {
          opacity: 0;
          transition: $t_fade;

          &.swiper-slide-active {
            opacity: 1;
          }
        }
      }

      .arw_prev,
      .arw_next {
        position: absolute;
        z-index: 1;
        top: 50%;
        @include responsive((
          margin-top: -96,
        ));
        @include mq(sp) {
          margin-top: mycalcSP(-96);
        }
      }

      .arw_prev {
        @include responsive((
          left: -280,
        ));
        @include mq(sp) {
          left: mycalcSP(-280);
        }
      }

      .arw_next {
        @include responsive((
          right: -280,
        ));
        @include mq(sp) {
          right: mycalcSP(-280);
        }
      }
    }

    .swiper-scrollbar {
      position: relative;
      width: calc(100vw - ((100vw - 1152px)*.5));
      @include responsive((
        height: 10,
        //margin-left: 48,
      ));
      @include mq(pc) {
        width: calc(100vw - ((100vw - #{mycalc(2304)})*.5));
      }
      @include mq(sp) {
        width: calc(100vw - #{mycalcSP(72)});
        height: mycalcSP(10);
        margin-left: 0;
        margin-top: mycalcSP(-2);
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        background: $c_white;
        @include responsive((
          height: 2,
          top: 5,
        ));
        @include mq(sp) {
          height: mycalcSP(2);
          top: mycalc(5);
        }
      }

      &-drag {
        cursor: pointer;
        position: relative;
        @include responsive((
          height: 10,
        ));
        @include mq(sp) {
          height: mycalcSP(10);
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          width: 100%;
          background: $c_brand;
          @include responsive((
            height: 2,
            top: 5,
          ));
          @include mq(sp) {
            height: mycalcSP(2);
            top: mycalc(5);
          }
        }
      }
    }
  }
}


.wave {
  position: absolute;
  z-index: -1;
  opacity: .2;

  .about_init & {
    @include responsive((
      width: 1000,
      height: 536,
      top: 240,
      right: -640,
    ));
    @include mq(sp) {
      width: mycalcSP(560);
      height: mycalcSP(300);
      top: mycalcSP(-144);
      right: mycalcSP(-72);
    }
  }

  .about_kyoto & {
    @include responsive((
      width: 652,
      height: 624,
      top: -326,
      left: 240,
    ));
    @include mq(sp) {
      width: mycalcSP(360);
      height: mycalcSP(348);
      top: mycalcSP(-240);
      left: mycalcSP(120);
    }
  }

  .about_shop & {
    @include responsive((
      width: 652,
      height: 624,
      top: -112,
      left: 1100,
    ));
    @include mq(sp) {
      width: mycalcSP(360);
      height: mycalcSP(348);
      top: mycalcSP(-240);
      left: mycalcSP(240);
    }
  }
}


.bnr {
  box-sizing: border-box;
  background: $c_pink_light;
  @include responsive((
    width: 2400,
    padding: 240 320,
    margin: 80 auto 240,
  ));
  @include mq(sp) {
    width: mycalcSP(750);
    padding: mycalcSP(96 55);
    margin: mycalcSP(0 auto 240);
  }

  &_item {
    &:not(:first-child) {
      @include responsive((
        margin-top: 96
      ));
      @include mq(sp) {
        margin-top: mycalcSP(48);
      }
    }

    &_img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

.not_found {

  .btn {
    justify-content: center;
    @include responsive((
      margin-top: 240
    ));
    @include mq(sp) {
      margin-top: mycalcSP(240);
    }
  }
}